import React from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

import pc from "../../assets/northstar/pc.png";
import eo from "../../assets/northstar/eo.png";
import yc from "../../assets/northstar/yc.png";
import nn from "../../assets/northstar/nn.png";
import teamLogo from "../../assets/northstar/team.png";
import chartLogo from "../../assets/northstar/chart_line.png";
import applicationLogo from "../../assets/northstar/application.png";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";
import BannerComponent from "../../components/Banner";

type DataScienceCardProps = {
  title: string;
  desc: string;
  icon: any;
};
const DataScienceCard: React.FC<DataScienceCardProps> = ({
  title,
  desc,
  icon,
}) => {
  return (
    <div className="max-w-[450px] mt-14">
      <div className="flex items-end">
        <img src={icon} alt={title} className="object-contain h-16 lg:h-24" />
        <div className="font-sans font-semibold text-2xl ml-8">{title}</div>
      </div>
      <div className="h-[1px] bg-gray-light w-full my-4"></div>
      <div className="">
        <p className="text-sm text-gray-dark">{desc}</p>
      </div>
    </div>
  );
};

const resourcesPage: React.FC = () => {
  const queryResults = useStaticQuery(query);
  const howWeHelpContent = queryResults.howWeHelp.edges[0].node;

  return (
    <NorthstarPageLayout title="Resources">
      <>
        <BannerComponent
          title="Resources"
          description="See how our experts in Data Science, Go-to-Market, and Talent are
              helping our portfolio companies."
        />
        <div>
          <div className="flex pb-4 border-b border-b-gray-light items-center">
            <img src={chartLogo} className="h-7"></img>
            <div className="ml-3 font-semibold font-title text-2xl">
              How we help
            </div>
          </div>
          <div className="my-14 max-w-[1080px]">
            <div className="flex">
              <div className="bg-white rounded-3xl p-2 xl:p-10 grid lg:grid-cols-3 gap-6 justify-center">
                <div className="bg-blue rounded-3xl text-center max-w-[350px] lg:max-w-[420px]">
                  <div className="p-6 xl:p-10">
                    <div className="text-2xl text-white font-title-mobile leading-none mb-6">
                      {
                        howWeHelpContent.howWeHelpLeftColumnTitle.data
                          .childMarkdownRemark.rawMarkdownBody
                      }
                    </div>
                    <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold text-white">
                        {
                          howWeHelpContent.howWeHelpLeftColumnItemOne.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                    <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold text-white">
                        {
                          howWeHelpContent.howWeHelpLeftColumnItemTwo.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                    <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold text-white">
                        {
                          howWeHelpContent.howWeHelpLeftColumnItemThree.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
                <div className="bg-off-white rounded-3xl text-center max-w-[350px] lg:max-w-[420px]">
                  <div className="p-6 xl:p-10">
                    <div className="text-2xl font-title-mobile leading-none mb-6">
                      {
                        howWeHelpContent.howWeHelpMiddleColumnTitle.data
                          .childMarkdownRemark.rawMarkdownBody
                      }
                    </div>
                    <div className="border-b border-b-gray-light border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold">
                        {
                          howWeHelpContent.howWeHelpMiddleColumnItemOne.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                    <div className="border-b border-b-gray-light border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold">
                        {
                          howWeHelpContent.howWeHelpMiddleColumnItemTwo.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                    <div className="border-b border-b-gray-light border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold">
                        {
                          howWeHelpContent.howWeHelpMiddleColumnItemThree.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
                <div className="bg-orange rounded-3xl text-center max-w-[350px] lg:max-w-[420px]">
                  <div className="p-6 xl:p-10">
                    <div className="text-2xl text-white font-title-mobile leading-none mb-6">
                      {
                        howWeHelpContent.howWeHelpRightColumnTitle.data
                          .childMarkdownRemark.rawMarkdownBody
                      }
                    </div>
                    <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold text-white">
                        {
                          howWeHelpContent.howWeHelpRightColumnItemOne.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                    <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold text-white">
                        {
                          howWeHelpContent.howWeHelpRightColumnItemTwo.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                    <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                      <ReactMarkdown className="text-left font-semibold text-white">
                        {
                          howWeHelpContent.howWeHelpRightColumnItemThree.data
                            .childMarkdownRemark.rawMarkdownBody
                        }
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex pb-4 border-b border-b-gray-light items-center">
            <img src={applicationLogo} className="h-7"></img>
            <div className="ml-3 font-semibold font-title text-2xl">
              Data Science
            </div>
          </div>
          <div className="mb-14 max-w-[1080px]">
            <div className="xl:flex justify-between w-full">
              {queryResults!
                .dataScienceCards!.edges.slice(0, 2)
                .map((card, i) => {
                  return (
                    <DataScienceCard
                      key={card.node.title}
                      icon={card.node.cardImage.localFile.publicURL}
                      desc={
                        card.node.description.data.childMarkdownRemark
                          .rawMarkdownBody
                      }
                      title={card.node.title}
                      index={i}
                    />
                  );
                })}
            </div>
            <div className="xl:flex justify-between w-full">
              {queryResults!
                .dataScienceCards!.edges.slice(2, 4)
                .map((card, i) => {
                  return (
                    <DataScienceCard
                      key={card.node.title}
                      icon={card.node.cardImage.localFile.publicURL}
                      desc={
                        card.node.description.data.childMarkdownRemark
                          .rawMarkdownBody
                      }
                      title={card.node.title}
                      index={i}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div>
          <div className="flex pb-4 border-b border-b-gray-light items-center">
            <img src={teamLogo} className="h-7"></img>
            <div className="ml-3 font-semibold font-title text-2xl">
              Contacts
            </div>
          </div>
          <div className="flex mt-6 gap-6 flex-col xl:flex-row pb-4">
            <div className="flex border border-off-white hover:border-orange rounded p-4 items-center">
              <img src={nn} className="h-20 rounded-full"></img>
              <div className="flex flex-col justify-center ml-4">
                <div className="font-semibold flex justify-between">
                  <span>Nickhil Nabar</span>
                  <a href="mailto:nickhil@bamelevate.com">
                    <EnvelopeIcon className="text-blue-light h-[1.2rem] cursor-pointer"></EnvelopeIcon>
                  </a>
                </div>
                <div className="text-sm">
                  Head of Data Science, San Francisco
                </div>
              </div>
            </div>
            <div className="flex border border-off-white hover:border-orange rounded p-4 items-center">
              <img src={eo} className="h-20 rounded-full"></img>
              <div className="flex flex-col justify-center ml-4">
                <div className="font-semibold flex justify-between">
                  <span>Elizabeth Ocken</span>
                  <a href="mailto:elizabeth@bamelevate.com">
                    <EnvelopeIcon className="text-blue-light h-[1.2rem] cursor-pointer"></EnvelopeIcon>
                  </a>
                </div>
                <div className="text-sm">Operating Director, San Francisco</div>
              </div>
            </div>
            <div className="flex border border-off-white hover:border-orange rounded p-4 items-center">
              <img src={pc} className="h-20 rounded-full"></img>
              <div className="flex flex-col justify-center ml-4">
                <div className="font-semibold flex justify-between">
                  <span>Perri Chilton</span>
                  <a href="mailto:perri@bamelevate.com">
                    <EnvelopeIcon className="text-blue-light h-[1.2rem] cursor-pointer"></EnvelopeIcon>
                  </a>
                </div>
                <div className="text-sm">
                  Operating Director, Talent, New York
                </div>
              </div>
            </div>
            <div className="flex border border-off-white hover:border-orange rounded p-4 items-center">
              <img src={yc} className="h-20 rounded-full"></img>
              <div className="flex flex-col justify-center ml-4">
                <div className="font-semibold flex justify-between">
                  <span>Yujin Chung</span>
                  <a href="mailto:yujin@bamelevate.com">
                    <EnvelopeIcon className="text-blue-light h-[1.2rem] cursor-pointer"></EnvelopeIcon>
                  </a>
                </div>
                <div className="text-sm">
                  Operating Director, Go to Market, San Francisco
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </NorthstarPageLayout>
  );
};

export default resourcesPage;

export const query = graphql`
  query ResourcePage {
    dataScienceCards: allStrapiDataScienceCard(
      sort: { order: ASC, fields: strapi_id }
    ) {
      edges {
        node {
          description {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          title
          cardImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
        }
      }
    }
    howWeHelp: allStrapiResourcePage {
      edges {
        node {
          howWeHelpLeftColumnItemThree {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpLeftColumnItemOne {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpLeftColumnItemTwo {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpLeftColumnTitle {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpMiddleColumnItemOne {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpMiddleColumnItemThree {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpMiddleColumnItemTwo {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpMiddleColumnTitle {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpRightColumnItemOne {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpRightColumnItemThree {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpRightColumnItemTwo {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          howWeHelpRightColumnTitle {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
        }
      }
    }
  }
`;
