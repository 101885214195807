import { navigate } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../../contexts/authContext";
import { SCOPES } from "../../constants";

const navigation = [
  {
    name: "Tools",
    path: "/tools",
    scope: SCOPES.TOOLS,
  },
  {
    name: "Insights",
    path: "/insights",
    scope: SCOPES.INSIGHTS,
  },
  {
    name: "Company Research",
    path: "/company_research",
    scope: SCOPES.COMPANY_RESEARCH,
  },
  {
    name: "Datasets",
    path: "/datasets",
    scope: SCOPES.DATASETS,
  },
  {
    name: "Resources",
    path: "/resources",
    scope: SCOPES.RESOURCES,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const [currentTab, setCurrentTab] = useState("Home");
  const { whoami } = useAuthContext();

  const navItems = useMemo(() => {
    if (!whoami) return;
    const items = [{ name: "Home", path: "/" }];
    for (const nav of navigation) {
      if (whoami.scope.includes(nav.scope)) items.push(nav);
    }
    return items;
  }, [JSON.stringify(whoami)]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    for (const tab of navItems ?? []) {
      if (currentPath.includes(tab.path)) setCurrentTab(tab.name);
    }
  }, []);

  const clickedTab = (tabName: string, path: string) => {
    setCurrentTab(tabName);
    navigate(path);
  };

  return (
    <div className="hidden md:block bg-white">
      <div className="min-w-[250px]"></div>
      <div className="flex flex-col pt-5 min-w-[250px] absolute">
        <div className="mt-5 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1 bg-white" aria-label="Sidebar">
            {(navItems ?? []).map((item) => (
              <div
                key={item.name}
                className={classNames(
                  item.name === currentTab
                    ? "font-bold text-black border-l-4 border-l-orange"
                    : "font-medium hover:font-bold",
                  "px-4 py-2 text-sm cursor-pointer",
                )}
                onClick={() => clickedTab(item.name, item.path)}
              >
                <span className="flex-1">{item.name}</span>
                {item.name === "Company Research" && (
                  <sup className="text-orange ml-1 font-bold">AI Enabled</sup>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
