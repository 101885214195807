import React from "react";
import headerBackground from "../../assets/northstar/header_bg.png";

interface IBannerProps {
  title: string;
  description: string;
}

const BannerComponent: React.FC<IBannerProps> = ({ title, description }) => {
  return (
    <div
      className="mb-8 w-full min-h-[150px] rounded shadow-lg"
      style={{
        backgroundImage: `url(${headerBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className="p-3">
        <div className="text-blue text-5xl font-title mb-4">{title}</div>
        <div className="text-gray-dark max-w-4xl">{description}</div>
      </div>
    </div>
  );
};

export default BannerComponent;
