import { HomeIcon, UsersIcon } from "@heroicons/react/24/outline";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";

const navigation = [
  { name: "Users", icon: HomeIcon, href: "#", path: "/admin/users" },
  { name: "Tools", icon: HomeIcon, href: "#", path: "/admin/tools" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminNavBar() {
  const [currentTab, setCurrentTab] = useState("Tools");

  useEffect(() => {
    const currentPath = window.location.pathname;
    for (const tab of navigation) {
      if (currentPath.includes(tab.name.toLowerCase())) setCurrentTab(tab.name);
    }
  }, []);

  const clickedTab = (tabName: string, path: string) => {
    setCurrentTab(tabName);
    navigate(path);
  };

  return (
    <div className="hidden md:flex flex-col overflow-y-auto bg-white pt-5 pb-4 min-w-[250px]">
      <div className="mt-5 flex flex-grow flex-col">
        <nav className="flex-1 space-y-1 bg-white" aria-label="Sidebar">
          {navigation.map((item) => (
            <div
              key={item.name}
              className={classNames(
                item.name === currentTab
                  ? "font-bold text-black border-l-4 border-l-orange"
                  : "font-medium hover:font-bold",
                "group flex items-center px-4 py-2 cursor-pointer",
              )}
              onClick={() => clickedTab(item.name, item.path)}
            >
              <span className="flex-1">{item.name}</span>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}
