import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import MenuBar from "../MenuBar";
import NavBar from "../NavBar";
import { navigate } from "gatsby";
import PageVisibility from "react-page-visibility";

import { useAuthContext } from "../../contexts/authContext";

import AdminNavBar from "../AdminNavBar";
import { SCOPES } from "../../constants";

interface NorthstarPageLayoutProps {
  title: string;
  children: JSX.Element;
  showAdminBar?: boolean;
  requiredScope?: SCOPES | null;
}

const nonRedirectingPaths = new Set(["/", "/login", "/logout", "/login/okta"]);

const getRedirectSearchParameters = () => {
  if (typeof window === "undefined") return "";

  if (nonRedirectingPaths.has(window.location.pathname)) return "";

  return encodeURIComponent(window.location.pathname + window.location.search);
};

const NorthstarPageLayout: React.FC<NorthstarPageLayoutProps> = ({
  title,
  children,
  showAdminBar = false,
  requiredScope = null,
}) => {
  // Access token isn't set ex post facto via `useEffect`,
  // so we use it here.
  const { accessToken, whoami } = useAuthContext();

  useEffect(() => {
    if (whoami && requiredScope) {
      if (!whoami.scope.includes(requiredScope)) navigate("/404");
    }
  });

  useEffect(() => {
    if (!accessToken) {
      const redirectURI = getRedirectSearchParameters();

      if (redirectURI) return navigate(`/login?redirect_to=${redirectURI}`);

      return navigate("/login");
    }
  }, [accessToken]);

  const handleVisibilityChange = (isVisible: boolean) => {
    // Check if session cookies still exist when a user navigates back to the Northstar tab in their browser.
    if (isVisible && !accessToken) navigate("/login");
  };

  return (
    <PageVisibility
      onChange={(isVisible: boolean) => handleVisibilityChange(isVisible)}
    >
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
        </Helmet>

        <main className="w-screen h-screen bg-off-white flex flex-center overflow-y-scroll overflow-x-auto scrollbar-thin scrollbar-thumb-gray-light scrollbar-track-off-white scrollbar-thumb-rounded">
          {accessToken && (
            <>
              <MenuBar NorthstarMenu={true} className={"bg-white"} />
              <div className="flex flex-row pt-[100px] md:pt-[100px] min-h-full min-w-full h-fit">
                {showAdminBar ? <AdminNavBar /> : <NavBar></NavBar>}
                <div className="px-12 py-6 bg-off-white md:border-l md:border-l-gray-light grow">
                  {children}
                </div>
              </div>
            </>
          )}
        </main>
      </div>
    </PageVisibility>
  );
};

export default NorthstarPageLayout;
